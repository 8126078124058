@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1740px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1597px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1240px) {
  /*====================================================================/
  /*--header breakpoint 以上--
  /====================================================================*/
}
@media (min-width: 1024px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1739px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1239px) {
  /*====================================================================/
  /*--header breakpoint 未満--
  /====================================================================*/
}
@media (max-width: 1596px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1023px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1023px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1024px) and (max-width: 1596px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1597px) and (max-width: 1739px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

index.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  padding-top: 64px;
  position: relative;
}
@media (min-width: 1240px) {
  .main-view {
    padding-top: 80px;
  }
}
.main-view::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  background: #C30D23;
  width: 68%;
  height: 100%;
  border-top-right-radius: clamp(64px, 8.533vw, 128px);
  border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
}
@media (min-width: 768px) {
  .main-view::before {
    width: 40%;
  }
}
@media (min-width: 1597px) {
  .main-view::before {
    width: 53%;
  }
}
@media (min-width: 1920px) {
  .main-view::before {
    width: calc(50% + 20px);
  }
}
.main-view__inner {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vw;
}
@media (min-width: 1024px) {
  .main-view__inner {
    align-items: center;
    height: 54vw;
  }
}
@media (min-width: 1597px) {
  .main-view__inner {
    height: 810px;
  }
}
@media (min-width: 1920px) {
  .main-view__inner {
    height: 42.1875vw;
  }
}
@media (max-width: 1023px) {
  .main-view__inner {
    align-items: flex-end;
  }
}

.main-view__bg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 86%;
  height: 80%;
}
@media (min-width: 1024px) {
  .main-view__bg {
    width: 76.5625%;
    height: 100%;
  }
}
.main-view__bg__inner {
  overflow: hidden;
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  height: 100%;
}
.main-view__bg__inner picture {
  height: 100%;
}

.main-view__catch {
  background: #FFFFFF;
  position: relative;
  z-index: 3;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  max-width: 75vw;
  width: 100%;
  padding: clamp(2em, 8vw, 8rem) clamp(2em, 9.38vw, 8rem) clamp(2em, 8vw, 8rem) 0;
}
@media (min-width: 768px) {
  .main-view__catch {
    max-width: 70vw;
  }
}
@media (min-width: 1024px) {
  .main-view__catch {
    padding: clamp(4em, 6.4vw, 6rem) clamp(6em, 8.533vw, 8rem) clamp(4em, 6.4vw, 6rem) 0;
    width: 50vw;
  }
}
@media (min-width: 1597px) {
  .main-view__catch {
    padding: 7em 12em 7em 0;
    width: 42vw;
    min-width: 760px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (min-width: 1920px) {
  .main-view__catch {
    height: 23.95vw;
  }
}
@media (max-width: 1023px) {
  .main-view__catch {
    margin-bottom: clamp(2rem, 3vw, 3rem);
  }
}
.main-view__catch__inner {
  position: relative;
  padding-left: 5vw;
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 1740px) {
  .main-view__catch__inner {
    padding-left: 6.77vw;
  }
}
.main-view__catch__img {
  padding-bottom: 1em;
  margin-bottom: 1em;
  display: block;
  position: relative;
}
@media (min-width: 1024px) {
  .main-view__catch__img {
    padding-bottom: 2em;
    margin-bottom: 2em;
  }
}
@media (max-width: 1023px) {
  .main-view__catch__img {
    width: min(54vw, 420px);
  }
}
.main-view__catch__img::before {
  content: "";
  width: 100vw;
  height: 1px;
  background: #C30D23;
  position: absolute;
  right: 0;
  bottom: 0;
}
.main-view__catch__img::after {
  content: "";
  width: 100vw;
  height: 1px;
  background: #FFFFFF;
  position: absolute;
  right: 0;
  bottom: 0;
  transition: all 0.8s ease 0s;
}
.main-view__catch__img.active::after {
  width: 0;
}
.main-view__catch__tit {
  display: block;
  line-height: 1.2;
  font-size: clamp(14px, 5vw, 40px);
}
@media (min-width: 1024px) {
  .main-view__catch__tit {
    font-size: clamp(30px, 3vw, 40px);
  }
}
@media (min-width: 1597px) {
  .main-view__catch__tit {
    font-size: clamp(32px, 2.13vw, 40px);
  }
}

/* common
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
/* top-aboutus
----------------------------------------------------------------*/
.top-aboutus {
  position: relative;
  padding-top: clamp(6rem, 12vw, 12rem);
  padding-bottom: clamp(2rem, 4vw, 4rem);
  margin-bottom: clamp(4rem, 6vw, 6rem);
}
@media (min-width: 768px) {
  .top-aboutus {
    margin-bottom: clamp(6rem, 9vw, 9rem);
    padding-bottom: clamp(4rem, 6vw, 6rem);
  }
}
.top-aboutus__flex {
  display: flex;
}
@media (min-width: 768px) {
  .top-aboutus__flex {
    justify-content: space-between;
    column-gap: 8%;
  }
}
@media (min-width: 1597px) {
  .top-aboutus__flex {
    column-gap: 9.63%;
  }
}
@media (max-width: 767px) {
  .top-aboutus__flex {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
.top-aboutus__add {
  position: absolute;
  bottom: 0;
  display: block;
  font-size: clamp(40px, 10.64vw, 200px);
  font-family: 'Roboto', sans-serif;
  color: #EDEEF6;
  z-index: 4;
  font-weight: 900;
}
@media (min-width: 768px) {
  .top-aboutus__add {
    font-size: clamp(74px, 9.64vw, 200px);
    right: -0.6em;
  }
}
@media (max-width: 767px) {
  .top-aboutus__add {
    left: 15%;
    width: 100%;
  }
}
.top-aboutus__add__inner {
  line-height: 1;
  letter-spacing: 0.1em;
  overflow-wrap: normal;
  word-break: keep-all;
}

@media (min-width: 768px) {
  .top-aboutus__img {
    flex-basis: 50%;
    flex-shrink: 1;
  }
}
@media (min-width: 1597px) {
  .top-aboutus__img {
    flex-basis: 56.62%;
  }
}
.top-aboutus__img__inner {
  position: relative;
  margin: 0;
  border-radius: clamp(12px, 2.13vw, 32px);
  overflow: hidden;
  z-index: 1;
}
.top-aboutus__img__inner:after {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 1739px) {
  .top-aboutus__img__inner {
    height: 100%;
  }
}
@media (min-width: 1740px) {
  .top-aboutus__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-aboutus__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .top-aboutus__img__inner > div,
  .top-aboutus__img__inner figure,
  .top-aboutus__img__inner a,
  .top-aboutus__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .top-aboutus__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-aboutus__img__inner::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    position: relative;
  }
  .top-aboutus__img__inner > div,
  .top-aboutus__img__inner figure,
  .top-aboutus__img__inner a,
  .top-aboutus__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.top-aboutus__img__inner::after {
  transition: all 0.3s ease 0s;
}
@media (min-width: 768px) and (max-width: 1739px) {
  .top-aboutus__img__inner > div {
    height: 100%;
  }
}
.top-aboutus__img__inner:hover::after {
  background: rgba(0, 0, 0, 0.5);
}
.top-aboutus__img i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
  font-size: calc(3rem + 32 * (100vw - 376px) / 1124);
  z-index: 3;
}
@media (min-width: 768px) {
  .top-aboutus__img i {
    font-size: calc(3rem + 32 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .top-aboutus__img i {
    font-size: 5rem;
  }
}

.top-aboutus__txt {
  position: relative;
}
@media (min-width: 768px) {
  .top-aboutus__txt {
    flex-basis: 43%;
    flex-shrink: 0;
    padding-top: clamp(2rem, 5vw, 5rem);
    padding-bottom: clamp(3rem, 4vw, 4rem);
  }
}
@media (max-width: 767px) {
  .top-aboutus__txt {
    padding-top: clamp(2rem, 3vw, 3rem);
    padding-bottom: clamp(2rem, 4vw, 4rem);
  }
}
@media (min-width: 1597px) {
  .top-aboutus__txt {
    flex-basis: 32.53%;
  }
}
.top-aboutus__txt::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100vw;
  height: 1px;
  background: #C30D23;
}
.top-aboutus__txt .txt {
  line-height: 2;
}

.top-aboutus__tit {
  font-size: calc(1.5rem + 20 * (100vw - 280px) / 1220);
  margin-bottom: calc(0.4em + 1rem);
}
@media (min-width: 768px) {
  .top-aboutus__tit {
    font-size: calc(1.5rem + 24 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .top-aboutus__tit {
    font-size: 3rem;
  }
}
.top-aboutus__tit b {
  font-weight: 700;
  line-height: 1;
  display: block;
  padding-bottom: 0.4em;
}
.top-aboutus__tit em {
  font-style: normal;
  display: block;
  line-height: 1;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #C30D23;
  font-size: calc(1.14rem + 48 * (100vw - 280px) / 1220);
}
@media (min-width: 768px) {
  .top-aboutus__tit em {
    font-size: calc(1.25rem + 20 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .top-aboutus__tit em {
    font-size: 2.5rem;
  }
}

/* top-topics
----------------------------------------------------------------*/
.top-topics {
  position: relative;
}
.top-topics::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 50%;
  background: #C30D23;
  z-index: 1;
}
.top-topics__inner {
  position: relative;
  z-index: 2;
  background: #FDF7F7;
  border-top-left-radius: clamp(16px, 8.533vw, 128px);
  border-bottom-left-radius: clamp(16px, 8.533vw, 128px);
  padding-top: clamp(3rem, 4vw, 4rem);
  padding-left: clamp(5%, 8vw, 6rem);
  padding-bottom: clamp(2rem, 4vw, 4rem);
}
@media (min-width: 1597px) {
  .top-topics__inner {
    padding-top: clamp(2.5rem, 4vw, 4rem);
    padding-left: clamp(3rem, 8vw, 8rem);
    padding-bottom: clamp(2.5rem, 4vw, 4rem);
  }
}
.top-topics__inner::before {
  position: absolute;
  top: 0;
  left: calc(100% - 2px);
  content: "";
  width: 100vw;
  height: 100%;
  background: #FDF7F7;
}
.top-topics__flex {
  display: flex;
}
@media (min-width: 1597px) {
  .top-topics__flex {
    justify-content: flex-start;
    align-items: center;
    column-gap: 2.07%;
  }
}
@media (max-width: 1596px) {
  .top-topics__flex {
    flex-direction: column;
  }
}
@media (min-width: 1024px) and (max-width: 1596px) {
  .top-topics__left {
    display: flex;
    align-items: center;
    column-gap: 10%;
  }
}
@media (min-width: 1597px) {
  .top-topics__left {
    width: 27.93%;
  }
}
@media (max-width: 1596px) {
  .top-topics__tit {
    margin-bottom: clamp(1.5rem, 3vw, 3rem);
  }
}
.top-topics__tit b {
  display: block;
  font-weight: 700;
  font-size: calc(1.5rem + 38 * (100vw - 280px) / 1220);
}
@media (min-width: 1597px) {
  .top-topics__tit b {
    font-size: calc(2.5rem + 8 * (100vw - 1500px) / 160);
  }
}
@media (min-width: 1740px) {
  .top-topics__tit b {
    font-size: 3rem;
  }
}
.top-topics__tit em {
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  color: #C30D23;
  letter-spacing: 0;
  font-weight: 700;
  padding-left: 0.2em;
  font-size: calc(1.25rem + 38 * (100vw - 280px) / 1220);
}
@media (min-width: 1597px) {
  .top-topics__tit em {
    font-size: calc(1.75rem + 12 * (100vw - 1500px) / 160);
  }
}
@media (min-width: 1740px) {
  .top-topics__tit em {
    font-size: 2.5rem;
  }
}
.top-topics__btn {
  max-width: 320px;
}
@media (min-width: 1597px) {
  .top-topics__btn.is-pc {
    margin-top: clamp(3rem, 4vw, 4rem);
  }
}
@media (max-width: 1023px) {
  .top-topics__btn.is-pc {
    display: none;
  }
}
.top-topics__btn.is-sp {
  margin-top: clamp(3rem, 4vw, 4rem);
}
@media (min-width: 1024px) {
  .top-topics__btn.is-sp {
    display: none;
  }
}
@media (min-width: 1597px) {
  .top-topics__right {
    width: 70%;
  }
}
@media (min-width: 1024px) {
  .top-topics__right__flex {
    position: relative;
    display: flex;
    column-gap: 7.58%;
  }
  .top-topics__right__flex::before {
    position: absolute;
    top: 1em;
    left: 47.57%;
    content: "";
    width: 1px;
    height: calc(100% - 2em);
    background: #D5DEE6;
  }
}

@media (min-width: 1024px) {
  .topics-newest {
    width: 43.78%;
  }
}

.top-topics__list {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .top-topics__list {
    width: 48.64%;
  }
}

.top-topics__newestbox {
  position: relative;
  text-align: left;
}
.top-topics__newestbox .topics-box__new {
  position: absolute;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  z-index: 2;
}
@media (min-width: 1024px) {
  .top-topics__newestbox .topics-box__new {
    right: 7%;
    bottom: calc(100% - 2em);
    font-size: 0.9em;
  }
}
@media (max-width: 1023px) {
  .top-topics__newestbox .topics-box__new {
    font-size: 0.75em;
    top: 0.75em;
    left: 2%;
  }
}
.top-topics__newestbox .topics-box__new span {
  padding: 0.5em 0.75em;
  display: inline-block;
  color: #FFFFFF;
  background: #e3021d;
  border-radius: 3px;
}
.top-topics__newestbox a {
  display: block;
  position: relative;
  text-decoration: none !important;
  height: 100%;
  padding: 1em 0;
}
@media (max-width: 1023px) {
  .top-topics__newestbox a {
    display: flex;
    column-gap: 4.08%;
    border-bottom: 1px solid #D5DEE6;
  }
}
.top-topics__newestbox a:hover .topics-box__tit {
  text-decoration: underline !important;
}
.top-topics__newestbox__img {
  margin: 0;
}
@media (max-width: 1023px) {
  .top-topics__newestbox__img {
    min-width: 80px;
    width: 25.92%;
    max-width: 140px;
    flex-shrink: 0;
  }
}
.top-topics__newestbox__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  border-radius: clamp(8px, 1.06vw, 16px);
}
.top-topics__newestbox__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.top-topics__newestbox__img__inner > div,
.top-topics__newestbox__img__inner figure,
.top-topics__newestbox__img__inner a,
.top-topics__newestbox__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (max-width: 1023px) {
  .top-topics__newestbox__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-topics__newestbox__img__inner::before {
    content: "";
    display: block;
    padding-top: 100%;
    position: relative;
  }
  .top-topics__newestbox__img__inner > div,
  .top-topics__newestbox__img__inner figure,
  .top-topics__newestbox__img__inner a,
  .top-topics__newestbox__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.top-topics__newestbox__txt {
  color: #202020;
}
@media (min-width: 1024px) {
  .top-topics__newestbox__txt {
    padding: 1rem 2%;
  }
}
@media (max-width: 1023px) {
  .top-topics__newestbox__txt {
    width: 70%;
    flex-shrink: 1;
  }
}
.top-topics__newestbox__date {
  color: #979DA4;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
.top-topics__newestbox__tit {
  font-size: calc(1rem + 3 * (100vw - 376px) / 1124);
  margin-bottom: 0.5rem;
}
@media (min-width: 1024px) {
  .top-topics__newestbox__tit {
    font-size: 1rem;
  }
}

.top-topics__box {
  position: relative;
  text-align: left;
}
.top-topics__box:not(:last-of-type) a {
  border-bottom: 1px solid #D5DEE6;
}
.top-topics__box .topics-box__new {
  position: absolute;
  top: 0.75em;
  left: 2%;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 0.75em;
  font-family: 'Roboto', sans-serif;
  z-index: 2;
}
.top-topics__box .topics-box__new span {
  padding: 0.5em 0.75em;
  display: inline-block;
  color: #FFFFFF;
  background: #e3021d;
  border-radius: 3px;
}
.top-topics__box a {
  display: block;
  position: relative;
  text-decoration: none !important;
  height: 100%;
  display: flex;
  column-gap: 4.08%;
  padding: 1em 0;
}
.top-topics__box a:hover .topics-box__tit {
  text-decoration: underline !important;
}
.top-topics__box__img {
  min-width: 80px;
  width: 25.92%;
  max-width: 140px;
  flex-shrink: 0;
  margin: 0;
}
.top-topics__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  border-radius: clamp(8px, 1.06vw, 16px);
}
.top-topics__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.top-topics__box__img__inner > div,
.top-topics__box__img__inner figure,
.top-topics__box__img__inner a,
.top-topics__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.top-topics__box__txt {
  color: #202020;
  width: 70%;
  flex-shrink: 1;
}
.top-topics__box__date {
  color: #979DA4;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
.top-topics__box__tit {
  font-size: calc(1rem + 3 * (100vw - 376px) / 1124);
  margin-bottom: 0.5rem;
}
@media (min-width: 1024px) {
  .top-topics__box__tit {
    font-size: 1rem;
  }
}

/* top-separate
----------------------------------------------------------------*/
.top-separate {
  padding-top: clamp(6rem, 12vw, 12rem);
  position: relative;
  margin-bottom: clamp(6rem, 12vw, 12rem);
}
.top-separate::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 20vw);
  background: #C30D23;
}
@media (min-width: 1024px) {
  .top-separate::before {
    height: 50%;
  }
}
.top-separate__flex {
  position: relative;
  z-index: 1;
  display: flex;
}
@media (min-width: 1024px) {
  .top-separate__flex {
    justify-content: space-between;
  }
}
@media (max-width: 1023px) {
  .top-separate__flex {
    flex-direction: column;
    row-gap: clamp(2rem, 9vw, 6rem);
  }
}

@media (min-width: 1024px) {
  .top-separate__box {
    width: 48.4375%;
  }
}
@media (max-width: 1023px) {
  .top-separate__box {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1024px) {
  .top-separate__box.is-pouring .top-separate__box__img__inner {
    border-top-right-radius: clamp(64px, 8.533vw, 128px);
  }
}
@media (min-width: 1024px) {
  .top-separate__box.is-pouring .top-separate__box__txt {
    border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
  }
}
@media (min-width: 1597px) {
  .top-separate__box.is-pouring .top-separate__box__txt {
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 1024px) {
  .top-separate__box.is-pouring .top-separate__box__txt__inner {
    padding-right: 12%;
  }
}
@media (min-width: 1024px) {
  .top-separate__box.is-sales .top-separate__box__img__inner {
    border-top-left-radius: clamp(64px, 8.533vw, 128px);
  }
}
@media (min-width: 1024px) {
  .top-separate__box.is-sales .top-separate__box__txt {
    border-bottom-left-radius: clamp(64px, 8.533vw, 128px);
  }
}
@media (min-width: 1597px) {
  .top-separate__box.is-sales .top-separate__box__txt {
    display: flex;
  }
}
@media (min-width: 1024px) {
  .top-separate__box.is-sales .top-separate__box__txt__inner {
    padding-left: 12%;
  }
}
.top-separate__box__inner {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .top-separate__box__inner {
    height: 100%;
  }
}

.top-separate__box__img {
  margin: 0;
}
.top-separate__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}
.top-separate__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 50%;
  position: relative;
}
.top-separate__box__img__inner > div,
.top-separate__box__img__inner figure,
.top-separate__box__img__inner a,
.top-separate__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (max-width: 1023px) {
  .top-separate__box__img__inner {
    border-top-left-radius: clamp(16px, 4.26vw, 64px);
    border-top-right-radius: clamp(16px, 4.26vw, 64px);
  }
}
.top-separate__box__txt {
  flex-shrink: 1;
  background: #292D2E;
  color: #D5DEE6;
}
@media (min-width: 1024px) {
  .top-separate__box__txt {
    height: 100%;
  }
}
@media (max-width: 1023px) {
  .top-separate__box__txt {
    border-bottom-left-radius: clamp(16px, 4.26vw, 64px);
    border-bottom-right-radius: clamp(16px, 4.26vw, 64px);
  }
}
.top-separate__box__txt__inner {
  padding: clamp(3rem, 5vw, 5rem) 5% clamp(2rem, 3vw, 3rem);
  position: relative;
}
@media (min-width: 1024px) {
  .top-separate__box__txt__inner {
    max-width: 800px;
    padding-top: clamp(2rem, 4vw, 4rem);
    padding-bottom: clamp(4rem, 6vw, 6rem);
  }
}
.top-separate__box__txt .txt {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.top-separate__box__txt .txt ul {
  text-align: left;
  margin-bottom: 1em;
}
.top-separate__box__txt .txt ul li {
  line-height: 1.4;
  padding-left: 1.5rem;
  position: relative;
}
.top-separate__box__txt .txt ul li::before {
  content: "●";
  color: #4B4D4E;
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 0.8em;
}
.top-separate__box__txt .txt ul li:not(:last-child) {
  margin-bottom: 0.5em;
}
.top-separate__box__catch {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 0.9;
  letter-spacing: 0;
  color: #FFFFFF;
  position: absolute;
  z-index: 3;
  font-size: calc(2rem + 54 * (100vw - 280px) / 1220);
}
@media (min-width: 768px) {
  .top-separate__box__catch {
    font-size: calc(4rem + 36 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1024px) {
  .top-separate__box__catch {
    font-size: clamp(42px, 4.2vw, 80px);
    bottom: calc(100% - 2rem);
  }
}
@media (min-width: 1740px) {
  .top-separate__box__catch {
    font-size: 5rem;
  }
}
@media (max-width: 1023px) {
  .top-separate__box__catch {
    bottom: calc(100% - 0.5em);
  }
}
.top-separate__box__tit {
  font-weight: 700;
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1220);
  margin-bottom: calc(0.5rem + 0.5em);
}
@media (min-width: 1024px) {
  .top-separate__box__tit {
    font-size: calc(1.25rem + 14 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .top-separate__box__tit {
    font-size: 2.13rem;
  }
}

/* top-pumpcarlist
----------------------------------------------------------------*/
.top-pumpcarlist {
  position: relative;
  padding-bottom: clamp(3rem, 4vw, 4rem);
}
@media (min-width: 1024px) {
  .top-pumpcarlist {
    padding-top: clamp(10rem, 21vw, 330px);
  }
}
@media (min-width: 1597px) {
  .top-pumpcarlist {
    padding-top: 330px;
  }
}
@media (max-width: 1023px) {
  .top-pumpcarlist {
    padding-top: clamp(10rem, 50vw, 400px);
  }
}
.top-pumpcarlist::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 45%;
  background: #C30D23;
}
@media (min-width: 1024px) {
  .top-pumpcarlist::before {
    height: 25%;
  }
}
.top-pumpcarlist__bg {
  position: absolute;
  top: 0;
  right: 0;
}
@media (min-width: 1024px) {
  .top-pumpcarlist__bg {
    width: 48%;
    height: 75%;
  }
}
@media (min-width: 1597px) {
  .top-pumpcarlist__bg {
    width: 56.25%;
  }
}
@media (max-width: 1023px) {
  .top-pumpcarlist__bg {
    width: 90%;
    height: 55%;
  }
}
.top-pumpcarlist__bg__inner {
  overflow: hidden;
  margin: 0;
  z-index: 2;
  position: relative;
  border-top-left-radius: clamp(64px, 8.533vw, 128px);
  height: 100%;
}
.top-pumpcarlist__bg__inner > div {
  height: 100%;
}
@media (max-width: 1023px) {
  .top-pumpcarlist__bg__inner > div:before {
    content: "";
    background: rgba(50, 14, 14, 0.67);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.top-pumpcarlist__inner {
  position: relative;
  z-index: 3;
}
.top-pumpcarlist__inner__inner {
  background: #EDEEF6;
  border-top-right-radius: clamp(12px, 8.533vw, 128px);
  border-bottom-right-radius: clamp(12px, 8.533vw, 128px);
  position: relative;
  z-index: 3;
}
@media (min-width: 1024px) and (max-width: 1596px) {
  .top-pumpcarlist__inner__inner {
    width: 64.25%;
    padding-left: 5%;
  }
}
@media (min-width: 1597px) {
  .top-pumpcarlist__inner__inner {
    width: 54.16%;
    min-height: 570px;
    display: flex;
    justify-content: flex-end;
  }
}
@media (max-width: 1023px) {
  .top-pumpcarlist__inner__inner {
    width: 90%;
  }
}

.top-pumpcarlist__txt {
  position: relative;
}
@media (min-width: 1024px) {
  .top-pumpcarlist__txt {
    padding: clamp(2rem, 3vw, 6rem) clamp(5rem, 10vw, 10rem) clamp(5rem, 7vw, 7rem) 0;
  }
}
@media (min-width: 1597px) {
  .top-pumpcarlist__txt {
    max-width: 830px;
    padding: clamp(2rem, 3vw, 6rem) clamp(5rem, 14vw, 14rem) clamp(5rem, 7vw, 7rem) 0;
  }
}
@media (max-width: 1023px) {
  .top-pumpcarlist__txt {
    padding: 9rem 6% clamp(4rem, 6vw, 6rem);
  }
}
@media (max-width: 767px) {
  .top-pumpcarlist__txt {
    padding-top: clamp(4.5rem, 14vw, 9rem);
  }
}
.top-pumpcarlist__txt__catch {
  position: absolute;
  left: 6%;
  z-index: 4;
  line-height: 0.9;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  font-size: calc(2.5rem + 70 * (100vw - 280px) / 1220);
  color: #C30D23;
}
@media (min-width: 1024px) {
  .top-pumpcarlist__txt__catch {
    bottom: calc(100% - 0.12em);
    left: 0;
    font-size: calc(4.5rem + 28 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .top-pumpcarlist__txt__catch {
    font-size: 6.88rem;
  }
}
@media (max-width: 1023px) {
  .top-pumpcarlist__txt__catch {
    bottom: calc(100% - 1.4em);
  }
}
.top-pumpcarlist__txt__tit {
  font-weight: 700;
  margin-bottom: calc(1rem + 0.5em);
  font-size: calc(1rem + 20 * (100vw - 280px) / 1220);
}
@media (min-width: 1024px) {
  .top-pumpcarlist__txt__tit {
    font-size: calc(1.25rem + 14 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1597px) {
  .top-pumpcarlist__txt__tit {
    font-size: 2.25rem;
  }
}
@media (min-width: 768px) {
  .top-pumpcarlist__txt .txt {
    font-weight: 500;
    line-height: 2;
  }
}

.top-pumpcarlist__img {
  position: absolute;
  z-index: 4;
}
@media (min-width: 1024px) {
  .top-pumpcarlist__img {
    bottom: -4rem;
    left: calc(60% - 5vw);
    width: 40%;
  }
}
@media (min-width: 1597px) {
  .top-pumpcarlist__img {
    left: calc(50% - 2rem);
    bottom: -5rem;
    width: 35.93%;
  }
}
@media (max-width: 1023px) {
  .top-pumpcarlist__img {
    bottom: calc(100% - 10vw);
    left: 50%;
    width: 55%;
  }
}
@media (max-width: 767px) {
  .top-pumpcarlist__img {
    left: 60%;
    width: 50%;
  }
}
.top-pumpcarlist__img__inner {
  margin: 0;
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  border-radius: clamp(8px, 2.12vw, 32px);
}
.top-pumpcarlist__img__inner::before {
  content: "";
  display: block;
  padding-top: 75%;
  position: relative;
}
.top-pumpcarlist__img__inner > div,
.top-pumpcarlist__img__inner figure,
.top-pumpcarlist__img__inner a,
.top-pumpcarlist__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (min-width: 1024px) {
  .top-pumpcarlist__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-pumpcarlist__img__inner::before {
    content: "";
    display: block;
    padding-top: 57.14%;
    position: relative;
  }
  .top-pumpcarlist__img__inner > div,
  .top-pumpcarlist__img__inner figure,
  .top-pumpcarlist__img__inner a,
  .top-pumpcarlist__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

/* top-recruit
----------------------------------------------------------------*/
.top-recruit {
  position: relative;
  padding-top: clamp(3rem, 11vw, 11rem);
  margin-bottom: clamp(3rem, 11vw, 11rem);
}
@media (min-width: 768px) {
  .top-recruit {
    margin-bottom: clamp(6rem, 9vw, 9rem);
  }
}
@media (min-width: 1597px) {
  .top-recruit {
    padding-top: 11rem;
    margin-bottom: 9rem;
  }
}
.top-recruit::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #C30D23;
  height: 30%;
}
@media (min-width: 768px) {
  .top-recruit::before {
    height: calc(9vw + 11rem);
  }
}
@media (min-width: 1597px) {
  .top-recruit::before {
    height: 22rem;
  }
}
.top-recruit__flex {
  display: flex;
}
@media (min-width: 768px) {
  .top-recruit__flex {
    column-gap: 8%;
  }
}
@media (min-width: 1597px) {
  .top-recruit__flex {
    column-gap: 8.45%;
  }
}
@media (max-width: 767px) {
  .top-recruit__flex {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}

@media (min-width: 768px) {
  .top-recruit__img {
    flex-basis: 50%;
    flex-shrink: 1;
  }
}
@media (min-width: 1597px) {
  .top-recruit__img {
    flex-basis: 47.59%;
  }
}
.top-recruit__img__inner {
  position: relative;
  margin: 0;
  border-radius: clamp(12px, 2.13vw, 32px);
  overflow: hidden;
  z-index: 1;
}
.top-recruit__img__inner:after {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 1739px) {
  .top-recruit__img__inner {
    height: 100%;
  }
}
@media (min-width: 1740px) {
  .top-recruit__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-recruit__img__inner::before {
    content: "";
    display: block;
    padding-top: 71.42%;
    position: relative;
  }
  .top-recruit__img__inner > div,
  .top-recruit__img__inner figure,
  .top-recruit__img__inner a,
  .top-recruit__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .top-recruit__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-recruit__img__inner::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    position: relative;
  }
  .top-recruit__img__inner > div,
  .top-recruit__img__inner figure,
  .top-recruit__img__inner a,
  .top-recruit__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.top-recruit__img__inner::after {
  transition: all 0.3s ease 0s;
}
@media (min-width: 768px) and (max-width: 1739px) {
  .top-recruit__img__inner > div {
    height: 100%;
  }
}
.top-recruit__img__inner:hover::after {
  background: rgba(0, 0, 0, 0.5);
}
.top-recruit__img i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
  font-size: calc(3rem + 32 * (100vw - 376px) / 1124);
  z-index: 3;
}
@media (min-width: 768px) {
  .top-recruit__img i {
    font-size: calc(3rem + 32 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .top-recruit__img i {
    font-size: 5rem;
  }
}

.top-recruit__txt {
  position: relative;
}
@media (min-width: 768px) {
  .top-recruit__txt {
    flex-basis: 43%;
    flex-shrink: 0;
    padding-top: 11rem;
    padding-bottom: clamp(3rem, 4vw, 4rem);
  }
}
@media (max-width: 767px) {
  .top-recruit__txt {
    padding-top: clamp(2rem, 3vw, 3rem);
    padding-bottom: clamp(2rem, 4vw, 4rem);
  }
}
@media (min-width: 1597px) {
  .top-recruit__txt {
    flex-basis: 39.15%;
  }
}
.top-recruit__txt::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100vw;
  height: 1px;
  background: #C30D23;
}
.top-recruit__txt .txt {
  line-height: 2;
}
.top-recruit__txt__inner {
  position: relative;
  z-index: 2;
}
.top-recruit__txt__add {
  position: absolute;
  display: block;
  font-size: clamp(70px, 18.62vw, 200px);
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 0.9;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .top-recruit__txt__add {
    color: #C30D23;
    -webkit-text-stroke: 2px #FFFFFF;
    text-stroke: 2px #FFFFFF;
    z-index: 2;
    font-size: clamp(96px, 12.5vw, 200px);
    bottom: 100%;
    left: -10%;
  }
}
@media (min-width: 1597px) {
  .top-recruit__txt__add {
    -webkit-text-stroke: 3px #FFFFFF;
    text-stroke: 3px #FFFFFF;
    bottom: calc(100% - 1.4rem);
    font-size: 12.5rem;
  }
}
@media (max-width: 767px) {
  .top-recruit__txt__add {
    display: none;
  }
}
.top-recruit__txt__add__inner {
  overflow-wrap: normal;
  word-break: keep-all;
}

.top-recruit__tit {
  font-size: calc(1.5rem + 20 * (100vw - 280px) / 1220);
  margin-bottom: calc(0.3em + 0.5rem);
}
@media (min-width: 768px) {
  .top-recruit__tit {
    padding-top: 1em;
    font-size: calc(1.5rem + 24 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .top-recruit__tit {
    font-size: 3rem;
  }
}
.top-recruit__tit b {
  font-weight: 700;
  line-height: 1;
  display: block;
  padding-bottom: 0.4em;
}
.top-recruit__tit em {
  font-style: normal;
  display: block;
  line-height: 1;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #C30D23;
  font-size: calc(1.25rem + 48 * (100vw - 376px) / 1124);
}
@media (min-width: 768px) {
  .top-recruit__tit em {
    font-size: calc(1.25rem + 20 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1597px) {
  .top-recruit__tit em {
    font-size: 2.5rem;
  }
}

/* top-company
----------------------------------------------------------------*/
.top-company {
  padding: clamp(6rem, 10vw, 10rem) 0 clamp(6rem, 12vw, 12rem);
  position: relative;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 1024px) {
  .top-company {
    margin-bottom: clamp(4rem, 7vw, 7rem);
    padding: clamp(14rem, 20vw, 20rem) 0 clamp(8rem, 10vw, 10rem);
  }
}
.top-company__add {
  position: absolute;
  top: 2rem;
  left: 2%;
  z-index: 3;
}
@media (min-width: 1024px) {
  .top-company__add {
    top: 4rem;
  }
}
.top-company__add__inner {
  position: relative;
  width: clamp(320px, 75vw, 1200px);
}
.top-company__inner {
  position: relative;
}
@media (max-width: 1023px) {
  .top-company__inner {
    padding-top: clamp(8rem, 40vw, 30rem);
  }
}
.top-company__inner__inner {
  position: relative;
  z-index: 2;
}
@media (min-width: 1024px) {
  .top-company__inner__inner {
    padding-left: 5vw;
  }
}
@media (min-width: 1740px) {
  .top-company__inner__inner {
    padding-left: 6.77vw;
  }
}
@media (max-width: 1023px) {
  .top-company__inner__inner {
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.top-company__tit {
  margin-bottom: calc(1rem + 1em);
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1220);
  padding-top: 1.5em;
}
@media (min-width: 1024px) {
  .top-company__tit {
    padding-top: 1.5em;
  }
}
@media (min-width: 1597px) {
  .top-company__tit {
    font-size: 2.25rem;
  }
}
.top-company__tit em {
  display: block;
  font-style: normal;
  color: #C30D23;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  letter-spacing: 0;
  font-size: calc(2.5rem + 40 * (100vw - 376px) / 1124);
  padding-bottom: 0.2em;
}
@media (min-width: 1597px) {
  .top-company__tit em {
    font-size: 5rem;
  }
}
.top-company__tit b {
  display: block;
  line-height: 1;
  font-weight: 700;
}

.top-company__txt {
  position: relative;
}

.top-company__img {
  position: absolute;
  z-index: 1;
  right: 0;
  width: 75%;
}
@media (min-width: 1024px) {
  .top-company__img {
    top: 50%;
    transform: translateY(-50%);
    width: 60%;
  }
}
@media (min-width: 1597px) {
  .top-company__img {
    width: calc(50% + 344px);
  }
}
@media (max-width: 1023px) {
  .top-company__img {
    top: 0;
  }
}
.top-company__img__inner {
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
  overflow: hidden;
  margin: 0;
}
@media (min-width: 1024px) {
  .top-company__img__inner {
    height: 480px;
  }
}
@media (min-width: 1597px) {
  .top-company__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-company__img__inner::before {
    content: "";
    display: block;
    padding-top: 36.92%;
    position: relative;
  }
  .top-company__img__inner > div,
  .top-company__img__inner figure,
  .top-company__img__inner a,
  .top-company__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .top-company__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-company__img__inner::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    position: relative;
  }
  .top-company__img__inner > div,
  .top-company__img__inner figure,
  .top-company__img__inner a,
  .top-company__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .top-company__img__inner > div {
    height: 100%;
  }
}
.top-company__img img {
  height: 100%;
}