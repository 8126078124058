@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/*====================================================================

index.css

=====================================================================*/

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	padding-top: 64px;
	position: relative;
	@include header-up {
		padding-top: 80px;
	}
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		background: $main_c;
		width: 68%;
		height: 100%;
		border-top-right-radius: clamp(64px, 8.533vw, 128px);
		border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
		@include media-breakpoint-up(md) {
			width: 40%;
		}
		@include media-breakpoint-up(xl) {
			width: 53%;
		}
		@include media-breakpoint-up(full) {
			width: calc(50% + 20px);
		}
	}
	&__inner {
		position: relative;
		display: flex;
		width: 100%;
		height: 100vw;
		@include media-breakpoint-up(lg) {
			align-items: center;
			height: 54vw;
		}
		@include media-breakpoint-up(xl) {
			height: 810px;
		}
		@include media-breakpoint-up(full) {
			height: 42.1875vw;
		}
		@include media-breakpoint-down(md) {
			align-items: flex-end;
		}
	}
}

.main-view__bg {
	position: absolute;
	top: 0;
	right: 0;
	z-index: z-index(module, part02);
	width: 86%;
	height: 80%;
	@include media-breakpoint-up(lg) {
		width: 76.5625%;
		height: 100%;
	}
	&__inner {
		overflow: hidden;
		border-top-left-radius: 9999px;
		border-bottom-left-radius: 9999px;
		height: 100%;
		picture {
			height: 100%;
		}
	}
}

.main-view__catch {
	background: $white;
	position: relative;
	z-index: z-index(module, part03);
	border-top-right-radius: 9999px;
	border-bottom-right-radius: 9999px;
	max-width: 75vw;
	width: 100%;
	padding: clamp(2em, 8vw, 8rem) clamp(2em, 9.38vw, 8rem) clamp(2em, 8vw, 8rem) 0;
	@include media-breakpoint-up(md) {
		max-width: 70vw;
	}
	@include media-breakpoint-up(lg) {
		padding: clamp(4em, 6.4vw, 6rem) clamp(6em, 8.533vw, 8rem) clamp(4em, 6.4vw, 6rem) 0;
		width: 50vw;
	}
	@include media-breakpoint-up(xl) {
		padding: 7em 12em 7em 0;
		width: 42vw;
		min-width: 760px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	@include media-breakpoint-up(full) {
		height: 23.95vw;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: clamp(2rem, 3vw, 3rem);
	}
	&__inner {
		position: relative;
		padding-left: 5vw;
		@include flex-end;
		@include media-breakpoint-up(xxl) {
			padding-left: 6.77vw;
		}
	}
	&__img {
		padding-bottom: 1em;
		margin-bottom: 1em;
		display: block;
		position: relative;
		@include media-breakpoint-up(lg) {
			padding-bottom: 2em;
			margin-bottom: 2em;
		}
		@include media-breakpoint-down(md) {
			width: min(54vw, 420px);
		}
		&::before {
			content: "";
			width: 100vw;
			height: 1px;
			background: $main_c;
			position: absolute;
			right: 0;
			bottom: 0;
		}
		&::after {
			content: "";
			width: 100vw;
			height: 1px;
			background: $white;
			position: absolute;
			right: 0;
			bottom: 0;
			@include transition(all,0.8,ease,0);
		}
		&.active {
			&::after {
				width: 0;
			}
		}
	}
	&__tit {
		display: block;
		@include line-h(1.2);
		@include fs_xxs(14, 40);
		@include media-breakpoint-up(lg) {
			@include fs_lg(30, 40);
		}
		@include media-breakpoint-up(xl) {
			@include fs_xl(32, 40);
		}
	}
}

/* common
----------------------------------------------------------------*/

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/

/* top-aboutus
----------------------------------------------------------------*/
.top-aboutus {
	position: relative;
	padding-top: clamp(6rem, 12vw, 12rem);
	padding-bottom: clamp(2rem, 4vw, 4rem);
	margin-bottom: clamp(4rem, 6vw, 6rem);
	@include media-breakpoint-up(md) {
		margin-bottom: clamp(6rem, 9vw, 9rem);
		padding-bottom: clamp(4rem, 6vw, 6rem);
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: space-between;
			column-gap: 8%;
		}
		@include media-breakpoint-up(xl) {
			column-gap: 9.63%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&__add {
		position: absolute;
		bottom: 0;
		display: block;
		@include fs-xs(40, 200);
		@include f-family(font02);
		color: $sub_c1;
		z-index: z-index(module, part04);
		@include f-w(900);
		@include media-breakpoint-up(md) {
			@include fs-md(74, 200);
			right: -0.6em;
		}
		@include media-breakpoint-down(sm) {
			left: 15%;
			width: 100%;
		}
		&__inner {
			@include line-h(1);
			@include l-sp(0.1em);
			overflow-wrap: normal;
			word-break: keep-all;
		}
	}
}

.top-aboutus__img {
	@include media-breakpoint-up(md) {
		flex-basis: 50%;
		flex-shrink: 1;
	}
	@include media-breakpoint-up(xl) {
		flex-basis: 56.62%;
	}
	&__inner {
		position: relative;
		margin: 0;
		@include radius_m(12px, 2.13vw, 32px);
		overflow: hidden;
		z-index: z-index(module, part01);
		@include filter_bg($black, 0.2, after);
		@include media-breakpoint-between(md, xl) {
			height: 100%;
		}
		@include media-breakpoint-up(xxl) {
			@include aspect-img(3, 2);
		}
		@include media-breakpoint-down(sm) {
			@include aspect-img(16, 9);
		}
		&::after {
			@include transition;
		}
		& > div {
			@include media-breakpoint-between(md, xl) {
				height: 100%;
			}
		}
		&:hover {
			&::after {
				background: rgba($black, 0.5);
			}
		}
	}
	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $white;
		@include f-s_xs(3, 32);
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			@include f-s_md(3, 32);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(80);
		}
	}
}

.top-aboutus__txt {
	position: relative;
	@include media-breakpoint-up(md) {
		flex-basis: 43%;
		flex-shrink: 0;
		padding-top: clamp(2rem, 5vw, 5rem);
		padding-bottom: clamp(3rem, 4vw, 4rem);
	}
	@include media-breakpoint-down(sm) {
		padding-top: clamp(2rem, 3vw, 3rem);
		padding-bottom: clamp(2rem, 4vw, 4rem);
	}
	@include media-breakpoint-up(xl) {
		flex-basis: 32.53%;
	}
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		width: 100vw;
		height: 1px;
		background: $main_c;
	}
	.txt {
		@include line-h(2);
	}
}

.top-aboutus__tit {
	@include f-s_xxs(1.5, 20);
	margin-bottom: calc(0.4em + 1rem);
	@include media-breakpoint-up(md) {
		@include f-s_md(1.5, 24);
	}
	@include media-breakpoint-up(xl) {
		@include f-size(48);
	}
	b {
		@include f-w(700);
		@include line-h(1);
		display: block;
		padding-bottom: 0.4em;
	}
	em {
		font-style: normal;
		display: block;
		@include line-h(1);
		@include f-w(700);
		@include f-family(font02);
		color: $main_c;
		@include f-s_xxs(1.14, 48);
		@include media-breakpoint-up(md) {
			@include f-s_md(1.25, 20);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(40);
		}
	}
}

/* top-topics
----------------------------------------------------------------*/
.top-topics {
	position: relative;
	&::before {
		position: absolute;
		left: 0;
		bottom: 0;
		content: "";
		width: 100%;
		height: 50%;
		background: $main_c;
		z-index: z-index(module, part01);
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		background: $bg_c1;
		border-top-left-radius: clamp(16px, 8.533vw, 128px);
		border-bottom-left-radius: clamp(16px, 8.533vw, 128px);
		padding-top: clamp(3rem, 4vw, 4rem);
		padding-left: clamp(5%, 8vw, 6rem);
		padding-bottom: clamp(2rem, 4vw, 4rem);
		@include media-breakpoint-up(xl) {
			padding-top: clamp(2.5rem, 4vw, 4rem);
			padding-left: clamp(3rem, 8vw, 8rem);
			padding-bottom: clamp(2.5rem, 4vw, 4rem);
		}
		&::before {
			position: absolute;
			top: 0;
			left: calc(100% - 2px);
			content: "";
			width: 100vw;
			height: 100%;
			background: $bg_c1;
		}
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(xl) {
			justify-content: flex-start;
			align-items: center;
			column-gap: 2.07%;
		}
		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}
	}
	&__left {
		@include media-breakpoint-only(lg) {
			display: flex;
			align-items: center;
			column-gap: 10%;
		}
		@include media-breakpoint-up(xl) {
			width: 27.93%;
		}
	}
	&__tit {
		@include media-breakpoint-down(lg) {
			margin-bottom: clamp(1.5rem, 3vw, 3rem);
		}
		b {
			display: block;
			@include f-w(700);
			@include f-s_xxs(1.5, 38);
			@include media-breakpoint-up(xl) {
				@include f-s_xl(2.5, 8);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(48);
			}
		}
		em {
			font-style: normal;
			@include f-family(font02);
			color: $main_c;
			@include l-sp(0);
			@include f-w(700);
			padding-left: 0.2em;
			@include f-s_xxs(1.25, 38);
			@include media-breakpoint-up(xl) {
				@include f-s_xl(1.75, 12);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(40);
			}
		}
	}
	&__btn {
		max-width: 320px;
		&.is-pc {
			@include media-breakpoint-up(xl) {
				margin-top: clamp(3rem, 4vw, 4rem);
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
		&.is-sp {
			margin-top: clamp(3rem, 4vw, 4rem);
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
	}
	&__right {
		@include media-breakpoint-up(xl) {
			width: 70%;
		}
		&__flex {
			@include media-breakpoint-up(lg) {
				position: relative;
				display: flex;
				column-gap: 7.58%;
				&::before {
					position: absolute;
					top: 1em;
					left: 47.57%;
					content: "";
					width: 1px;
					height: calc(100% - 2em);
					background: $l-gray;
				}
			}
		}
	}
}

.topics-newest {
	@include media-breakpoint-up(lg) {
		width: 43.78%;
	}
}

.top-topics__list {
	@include flex-column;
	@include media-breakpoint-up(lg) {
		width: 48.64%;
	}
}

.top-topics__newestbox {
	position: relative;
	text-align: left;
	.topics-box__new {
		position: absolute;
		@include line-h(1);
		@include l-sp(0.02em);
		@include f-w(700);
		@include f-family(font02);
		z-index: z-index(module, part02);
		@include media-breakpoint-up(lg) {
			right: 7%;
			bottom: calc(100% - 2em);
			font-size: 0.9em;
		}
		@include media-breakpoint-down(md) {
			font-size: 0.75em;
			top: 0.75em;
			left: 2%;
		}
		span {
			padding: 0.5em 0.75em;
			display: inline-block;
			color: $white;
			background: $hover_c;
			@include radius(3px);
		}
	}
	a {
		display: block;
		position: relative;
		@include dec-none;
		height: 100%;
		padding: 1em 0;
		@include media-breakpoint-down(md) {
			display: flex;
			column-gap: 4.08%;
			border-bottom: 1px solid $l-gray;
		}
		&:hover {
			.topics-box__tit {
				@include dec-line;
			}
		}
	}
	&__img {
		margin: 0;
		@include media-breakpoint-down(md) {
			min-width: 80px;
			width: 25.92%;
			max-width: 140px;
			flex-shrink: 0;
		}
		&__inner {
			@include aspect-img();
			overflow: hidden;
			@include radius_m;
			@include media-breakpoint-down(md) {
				@include aspect-img(1, 1);
			}
		}
	}
	&__txt {
		color: $txt_c;
		@include media-breakpoint-up(lg) {
			padding: 1rem 2%;
		}
		@include media-breakpoint-down(md) {
			width: 70%;
			flex-shrink: 1;
		}
	}
	&__date {
		color: $gray;
		@include f-family(font02);
		@include f-w(700);
	}
	&__tit {
		@include f-s_xs(1, 3);
		margin-bottom: 0.5rem;
		@include media-breakpoint-up(lg) {
			@include f-size(16);
		}
	}
}

.top-topics__box {
	position: relative;
	text-align: left;
	&:not(:last-of-type) {
		a {
			border-bottom: 1px solid $l-gray;
		}
	}
	.topics-box__new {
		position: absolute;
		top: 0.75em;
		left: 2%;
		@include line-h(1);
		@include l-sp(0.02em);
		@include f-w(700);
		font-size: 0.75em;
		@include f-family(font02);
		z-index: z-index(module, part02);
		span {
			padding: 0.5em 0.75em;
			display: inline-block;
			color: $white;
			background: $hover_c;
			@include radius(3px);
		}
	}
	a {
		display: block;
		position: relative;
		@include dec-none;
		height: 100%;
		display: flex;
		column-gap: 4.08%;
		padding: 1em 0;
		&:hover {
			.topics-box__tit {
				@include dec-line;
			}
		}
	}
	&__img {
		min-width: 80px;
		width: 25.92%;
		max-width: 140px;
		flex-shrink: 0;
		margin: 0;
		&__inner {
			@include aspect-img(1, 1);
			overflow: hidden;
			@include radius_m;
		}
	}
	&__txt {
		color: $txt_c;
		width: 70%;
		flex-shrink: 1;
	}
	&__date {
		color: $gray;
		@include f-family(font02);
		@include f-w(700);
	}
	&__tit {
		@include f-s_xs(1, 3);
		margin-bottom: 0.5rem;
		@include media-breakpoint-up(lg) {
			@include f-size(16);
		}
	}
}

/* top-separate
----------------------------------------------------------------*/
.top-separate {
	padding-top: clamp(6rem, 12vw, 12rem);
	position: relative;
	margin-bottom: clamp(6rem, 12vw, 12rem);
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100% - 20vw);
		background: $main_c;
		@include media-breakpoint-up(lg) {
			height: 50%;
		}
	}
	&__flex {
		position: relative;
		z-index: z-index(module, part01);
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 9vw, 6rem);
		}
	}
}

.top-separate__box {
	@include media-breakpoint-up(lg) {
		width: 48.4375%;
	}
	@include media-breakpoint-down(md) {
		width: 90%;
		@include m-a;
	}
	&.is-pouring {
		.top-separate__box__img__inner {
			@include media-breakpoint-up(lg) {
				border-top-right-radius: clamp(64px, 8.533vw, 128px);
			}
		}
		.top-separate__box__txt {
			@include media-breakpoint-up(lg) {
				border-bottom-right-radius: clamp(64px, 8.533vw, 128px);
			}
			@include media-breakpoint-up(xl) {
				@include flex-end;
			}
		}
		.top-separate__box__txt__inner {
			@include media-breakpoint-up(lg) {
				padding-right: 12%;
			}
		}
	}
	&.is-sales {
		.top-separate__box__img__inner {
			@include media-breakpoint-up(lg) {
				border-top-left-radius: clamp(64px, 8.533vw, 128px);
			}
		}
		.top-separate__box__txt {
			@include media-breakpoint-up(lg) {
				border-bottom-left-radius: clamp(64px, 8.533vw, 128px);
			}
			@include media-breakpoint-up(xl) {
				display: flex;
			}
		}
		.top-separate__box__txt__inner {
			@include media-breakpoint-up(lg) {
				padding-left: 12%;
			}
		}
	}
	&__inner {
		@include flex-column;
		@include media-breakpoint-up(lg) {
			height: 100%;
		}
	}
}

.top-separate__box {
	&__img {
		margin: 0;
		&__inner {
			@include aspect-img(2, 1);
			overflow: hidden;
			@include media-breakpoint-down(md) {
				border-top-left-radius: clamp(16px, 4.26vw, 64px);
				border-top-right-radius: clamp(16px, 4.26vw, 64px);
			}
		}
	}
	&__txt {
		flex-shrink: 1;
		background: $b-gray;
		color: $l-gray;
		@include media-breakpoint-up(lg) {
			height: 100%;
		}
		@include media-breakpoint-down(md) {
			border-bottom-left-radius: clamp(16px, 4.26vw, 64px);
			border-bottom-right-radius: clamp(16px, 4.26vw, 64px);
		}
		&__inner {
			padding: clamp(3rem, 5vw, 5rem) 5% clamp(2rem, 3vw, 3rem);
			position: relative;
			@include media-breakpoint-up(lg) {
				max-width: 800px;
				padding-top: clamp(2rem, 4vw, 4rem);
				padding-bottom: clamp(4rem, 6vw, 6rem);
			}
		}
		.txt {
			margin-bottom: clamp(2rem, 3vw, 3rem);
			ul {
				text-align: left;
				margin-bottom: 1em;
				& li {
					line-height: 1.4;
					padding-left: 1.5rem;
					position: relative;
					&::before {
						content: "●";
						color: $d-gray;
						position: absolute;
						left: 0;
						top: 0.2em;
						font-size: 0.8em;
					}
					&:not(:last-child) {
						margin-bottom: 0.5em;
					}
				}
			}
		}
	}
	&__catch {
		@include f-family(font02);
		@include f-w(700);
		@include line-h(0.9);
		@include l-sp(0);
		color: $white;
		position: absolute;
		z-index: z-index(module, part03);
		@include f-s_xxs(2, 54);
		@include media-breakpoint-up(md) {
			@include f-s_md(4, 36);
		}
		@include media-breakpoint-up(lg) {
			@include fs_lg(42, 80);
			bottom: calc(100% - 2rem);
		}
		@include media-breakpoint-up(xxl) {
			@include f-size(80);
		}
		@include media-breakpoint-down(md) {
			bottom: calc(100% - 0.5em);
		}
	}
	&__tit {
		@include f-w(700);
		@include f-s_xxs(1.14, 16);
		margin-bottom: calc(0.5rem + 0.5em);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.25, 14);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(34);
		}
	}
}

/* top-pumpcarlist
----------------------------------------------------------------*/
.top-pumpcarlist {
	position: relative;
	padding-bottom: clamp(3rem, 4vw, 4rem);
	@include media-breakpoint-up(lg) {
		padding-top: clamp(10rem, 21vw, 330px);
	}
	@include media-breakpoint-up(xl) {
		padding-top: 330px;
	}
	@include media-breakpoint-down(md) {
		padding-top: clamp(10rem, 50vw, 400px);
	}
	&::before {
		position: absolute;
		left: 0;
		bottom: 0;
		content: "";
		width: 100%;
		height: 45%;
		background: $main_c;
		@include media-breakpoint-up(lg) {
			height: 25%;
		}
	}
	&__bg {
		position: absolute;
		top: 0;
		right: 0;
		@include media-breakpoint-up(lg) {
			width: 48%;
			height: 75%;
		}
		@include media-breakpoint-up(xl) {
			width: 56.25%;
		}
		@include media-breakpoint-down(md) {
			width: 90%;
			height: 55%;
		}
		&__inner {
			overflow: hidden;
			margin: 0;
			z-index: z-index(module, part02);
			position: relative;
			border-top-left-radius: clamp(64px, 8.533vw, 128px);
			height: 100%;
			& > div {
				height: 100%;
				@include media-breakpoint-down(md) {
					@include filter_bg;
				}
			}
		}
	}
}

.top-pumpcarlist {
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
		&__inner {
			background: $sub_c1;
			border-top-right-radius: clamp(12px, 8.533vw, 128px);
			border-bottom-right-radius: clamp(12px, 8.533vw, 128px);
			position: relative;
			z-index: z-index(module, part03);
			@include media-breakpoint-only(lg) {
				width: 64.25%;
				padding-left: 5%;
			}
			@include media-breakpoint-up(xl) {
				width: 54.16%;
				min-height: 570px;
				@include flex-end;
			}
			@include media-breakpoint-down(md) {
				width: 90%;
			}
		}
	}
}

.top-pumpcarlist {
	&__txt {
		position: relative;
		@include media-breakpoint-up(lg) {
			padding: clamp(2rem, 3vw, 6rem) clamp(5rem, 10vw, 10rem) clamp(5rem, 7vw, 7rem) 0;
		}
		@include media-breakpoint-up(xl) {
			max-width: 830px;
			padding: clamp(2rem, 3vw, 6rem) clamp(5rem, 14vw, 14rem) clamp(5rem, 7vw, 7rem) 0;
		}
		@include media-breakpoint-down(md) {
			padding: 9rem 6% clamp(4rem, 6vw, 6rem);
		}
		@include media-breakpoint-down(sm) {
			padding-top: clamp(4.5rem, 14vw, 9rem);
		}
		&__catch {
			position: absolute;
			left: 6%;
			z-index: z-index(module, part04);

			@include line-h(0.9);
			@include f-family(font02);
			@include f-w(700);
			@include l-sp(0);
			@include f-s_xxs(2.5, 70);
			color: $main_c;
			@include media-breakpoint-up(lg) {
				bottom: calc(100% - 0.12em);
				left: 0;
				@include f-s_lg(4.5, 28);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(110);
			}
			@include media-breakpoint-down(md) {
				bottom: calc(100% - 1.4em);
			}
		}
		&__tit {
			@include f-w(700);
			margin-bottom: calc(1rem + 0.5em);
			@include f-s_xxs(1, 20);
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1.25, 14);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
		.txt {
			@include media-breakpoint-up(md) {
				@include f-w(500);
				@include line-h(2);
			}
		}
	}
}

.top-pumpcarlist {
	&__img {
		position: absolute;
		z-index: z-index(module, part04);
		@include media-breakpoint-up(lg) {
			bottom: -4rem;
			left: calc(60% - 5vw);
			width: 40%;
		}
		@include media-breakpoint-up(xl) {
			left: calc(50% - 2rem);
			bottom: -5rem;
			width: 35.93%;
		}
		@include media-breakpoint-down(md) {
			bottom: calc(100% - 10vw);
			left: 50%;
			width: 55%;
		}
		@include media-breakpoint-down(sm) {
			left: 60%;
			width: 50%;
		}
		&__inner {
			margin: 0;
			@include aspect-img(4, 3);
			overflow: hidden;
			@include radius_m(8px, 2.12vw, 32px);
			@include media-breakpoint-up(lg) {
				@include aspect-img(7, 4);
			}
		}
	}
}

/* top-recruit
----------------------------------------------------------------*/
.top-recruit {
	position: relative;
	padding-top: clamp(3rem, 11vw, 11rem);
	margin-bottom: clamp(3rem, 11vw, 11rem);
	@include media-breakpoint-up(md) {
		margin-bottom: clamp(6rem, 9vw, 9rem);
	}
	@include media-breakpoint-up(xl) {
		padding-top: 11rem;
		margin-bottom: 9rem;
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		background: $main_c;
		height: 30%;
		@include media-breakpoint-up(md) {
			height: calc(9vw + 11rem);
		}
		@include media-breakpoint-up(xl) {
			height: 22rem;
		}
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			column-gap: 8%;
		}
		@include media-breakpoint-up(xl) {
			column-gap: 8.45%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
}

.top-recruit__img {
	@include media-breakpoint-up(md) {
		flex-basis: 50%;
		flex-shrink: 1;
	}
	@include media-breakpoint-up(xl) {
		flex-basis: 47.59%;
	}
	&__inner {
		position: relative;
		margin: 0;
		@include radius_m(12px, 2.13vw, 32px);
		overflow: hidden;
		z-index: z-index(module, part01);
		@include filter_bg($black, 0.2, after);
		@include media-breakpoint-between(md, xl) {
			height: 100%;
		}
		@include media-breakpoint-up(xxl) {
			@include aspect-img(7, 5);
		}
		@include media-breakpoint-down(sm) {
			@include aspect-img(16, 9);
		}
		&::after {
			@include transition;
		}
		& > div {
			@include media-breakpoint-between(md, xl) {
				height: 100%;
			}
		}
		&:hover {
			&::after {
				background: rgba($black, 0.5);
			}
		}
	}
	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $white;
		@include f-s_xs(3, 32);
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			@include f-s_md(3, 32);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(80);
		}
	}
}

.top-recruit__txt {
	position: relative;
	@include media-breakpoint-up(md) {
		flex-basis: 43%;
		flex-shrink: 0;
		padding-top: 11rem;
		padding-bottom: clamp(3rem, 4vw, 4rem);
	}
	@include media-breakpoint-down(sm) {
		padding-top: clamp(2rem, 3vw, 3rem);
		padding-bottom: clamp(2rem, 4vw, 4rem);
	}
	@include media-breakpoint-up(xl) {
		flex-basis: 39.15%;
	}
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		width: 100vw;
		height: 1px;
		background: $main_c;
	}
	.txt {
		@include line-h(2);
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__add {
		position: absolute;
		display: block;
		@include fs-xs(70, 200);
		@include f-family(font02);
		@include f-w(700);
		@include line-h(0.9);
		@include l-sp(0);
		@include media-breakpoint-up(md) {
			color: $main_c;
			-webkit-text-stroke: 2px $white;
			text-stroke: 2px $white;
			z-index: z-index(module, part02);
			@include fs-md(96, 200);
			bottom: 100%;
			left: -10%;
		}
		@include media-breakpoint-up(xl) {
			-webkit-text-stroke: 3px $white;
			text-stroke: 3px $white;
			bottom: calc(100% - 1.4rem);
			@include f-size(200);
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
		&__inner {
			overflow-wrap: normal;
			word-break: keep-all;
		}
	}
}

.top-recruit__tit {
	@include f-s_xxs(1.5, 20);
	margin-bottom: calc(0.3em + 0.5rem);
	@include media-breakpoint-up(md) {
		padding-top: 1em;
		@include f-s_md(1.5, 24);
	}
	@include media-breakpoint-up(xl) {
		@include f-size(48);
	}
	b {
		@include f-w(700);
		@include line-h(1);
		display: block;
		padding-bottom: 0.4em;
	}
	em {
		font-style: normal;
		display: block;
		@include line-h(1);
		@include f-w(700);
		@include f-family(font02);
		color: $main_c;
		@include f-s_xs(1.25, 48);
		@include media-breakpoint-up(md) {
			@include f-s_md(1.25, 20);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(40);
		}
	}
}

/* top-company
----------------------------------------------------------------*/
.top-company {
	padding: clamp(6rem, 10vw, 10rem) 0 clamp(6rem, 12vw, 12rem);
	position: relative;
	margin-bottom: clamp(2rem, 3vw, 3rem);
	@include media-breakpoint-up(lg) {
		margin-bottom: clamp(4rem, 7vw, 7rem);
		padding: clamp(14rem, 20vw, 20rem) 0 clamp(8rem, 10vw, 10rem);
	}
	&__add {
		position: absolute;
		top: 2rem;
		left: 2%;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(lg) {
			top: 4rem;
		}
		&__inner {
			position: relative;
			width: clamp(320px, 75vw, 1200px);
		}
	}
	&__inner {
		position: relative;
		@include media-breakpoint-down(md) {
			padding-top: clamp(8rem, 40vw, 30rem);
		}
		&__inner {
			position: relative;
			z-index: z-index(module, part02);
			@include media-breakpoint-up(lg) {
				padding-left: 5vw;
			}
			@include media-breakpoint-up(xxl) {
				padding-left: 6.77vw;
			}
			@include media-breakpoint-down(md) {
				@include m-a;
				padding-left: map-get($contmargin, sp);
				padding-right: map-get($contmargin, sp);
			}
		}
	}
}

.top-company__tit {
	margin-bottom: calc(1rem + 1em);
	@include f-s_xxs(1.14, 16);
	padding-top: 1.5em;
	@include media-breakpoint-up(lg) {
		padding-top: 1.5em;
	}
	@include media-breakpoint-up(xl) {
		@include f-size(36);
	}
	em {
		display: block;
		font-style: normal;
		color: $main_c;
		@include f-w(700);
		@include f-family(font02);
		@include line-h(1);
		@include l-sp(0);
		@include f-s_xs(2.5, 40);
		padding-bottom: 0.2em;
		@include media-breakpoint-up(xl) {
			@include f-size(80);
		}
	}
	b {
		display: block;
		@include line-h(1);
		@include f-w(700);
	}
}

.top-company__txt {
	position: relative;
}

.top-company__img {
	position: absolute;
	z-index: z-index(module, part01);
	right: 0;
	width: 75%;
	@include media-breakpoint-up(lg) {
		top: 50%;
		transform: translateY(-50%);
		width: 60%;
	}
	@include media-breakpoint-up(xl) {
		width: calc(50% + 344px);
	}
	@include media-breakpoint-down(md) {
		top: 0;
	}
	&__inner {
		border-top-left-radius: 999px;
		border-bottom-left-radius: 999px;
		overflow: hidden;
		margin: 0;
		@include media-breakpoint-up(lg) {
			height: 480px;
		}
		@include media-breakpoint-up(xl) {
			@include aspect-img(65, 24);
		}
		@include media-breakpoint-down(md) {
			@include aspect-img(16, 9);
		}
		& > div {
			@include media-breakpoint-up(lg) {
				height: 100%;
			}
		}
	}
	img {
		height: 100%;
	}
}
